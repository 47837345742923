//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "layout/layout";
@import "./core/layout/docs/layout";

.ReactModal__Overlay{
    z-index: 101;
    background-color: rgba(0, 0, 0, 0.5) !important
}

.br-50{
    border-radius: 50% !important;
}

.overflow-eclilipse{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.mw-120px{
    max-width: 120px !important;
}

.table-sort-asc:after {
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 8' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.42111 2.06463C4.22088 1.96161 3.9637 1.9809 3.78597 2.12863L0.177181 5.12847C-0.046034 5.31402 -0.0602611 5.63049 0.145404 5.83532C0.351069 6.04015 0.698744 6.05578 0.921959 5.87023L4.14137 3.19406L7.06417 5.84414C7.27904 6.03896 7.62686 6.03835 7.84105 5.84278C8.05524 5.64721 8.05469 5.33073 7.83982 5.13591L4.54449 2.14806C4.50704 2.1141 4.46541 2.08629 4.42111 2.06463Z'/%3e%3c/svg%3e");
}
.table-sort-desc::after {
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 8' fill='%23A1A5B7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.42111 5.93537C4.22088 6.03839 3.9637 6.0191 3.78597 5.87137L0.177181 2.87153C-0.046034 2.68598 -0.060261 2.36951 0.145404 2.16468C0.351069 1.95985 0.698744 1.94422 0.921959 2.12977L4.14137 4.80594L7.06417 2.15586C7.27904 1.96104 7.62686 1.96165 7.84105 2.15722C8.05524 2.35279 8.05469 2.66927 7.83982 2.86409L4.54449 5.85194C4.50704 5.8859 4.46541 5.91371 4.42111 5.93537Z'/%3e%3c/svg%3e");
}
.table-sort:after, .table-sort-asc:after, .table-sort-desc:after {
    position: relative;
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    content: " ";
    bottom: auto;
    right: auto;
    left: auto;
    margin-left: 0.5rem;
}
.pointer{
    cursor: pointer;
}

.react-tagsinput-tag{
    background: #fff !important;
    border-radius: 5px !important;
    color: #191C31 !important;
    border: 1px solid #B5B5B6 !important;
    a{
        color: red !important;
    }
}
.react-tagsinput-input{
    width: 100% !important;
}

.textoverflow{
    overflow: hidden;
    max-width: 165px;
    text-overflow: ellipsis;
}

.f-12{
    font-size: 12px !important;
}
.PhoneInputInput{
    background-color: #F5F8FA;	
    border-color: #F5F8FA;
    color: #5E6278;
    transition: color 0.2s ease, background-color 0.2s ease;
    box-shadow: none !important;
    width: 100%;
    padding: 0.75rem 1rem !important;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    background-clip: padding-box;
    border: 0px solid #E4E6EF !important;
    appearance: none;
    border-radius: 0.475rem;
}

.fs-12{
    font-size: 12px;
}

.breakspaces{
    white-space: break-spaces;
}